import request from "../utils/request";

export const init = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "lamp/find",
        method: "get",
        query: query,
    });
};


