<template>
  <div class="lamp">
    <Header class="Header"></Header>
    <div class="bg" :style="heights">
      <canvas ref="canvas" class="canvas" :style="heights"></canvas>
      <div class="number">
        <p>当前作画数量：</p>
        <p>{{ creationNumber }}</p>
      </div>
      <div class="light">
        <p>距离点亮下一盏灯：</p>
        <p>{{ LightNextNumber }}</p>
      </div>
      <div class="target">
        <p>灿若星辰总目标:</p>
        <p>1260</p>
      </div>
      <img :src="urlImg" :style="Imgs" v-if="urlImg&&Start"/>
    </div>
    <div class="test"></div>
  </div>
</template>

<script>
import Header from "../components/Header";
import {init} from '../api/lamp'

export default {
  mounted() {
    let width = document.documentElement.clientWidth
    let height = document.documentElement.clientHeight
    let heights = 'height:' + (height - 60) + 'px;width:100%'
    this.heights = heights
    this.width = width
    this.height = height
    // this.Uptop()
    this.fachdata()
  },
  data() {
    return {
      heights: '',
      width: 0,
      height: 0,
      floor: 0,
      data: '',
      creationNumber: 0,
      LampData: 0,
      Max: 0,
      LightNextNumber: 0,
      urlImg: '',
      Imgs: '',
      Start: false
    }
  },
  components: {
    Header
  },
  beforeDestroy() {
    clearInterval(this.Starts)
  },
  methods: {
    fachdata() {
      init().then(res => {
        this.creationNumber = res.creationNumber
        this.LampData = res.LampData
        this.Max = res.Max
        this.LightNextNumber = res.LightNextNumber - res.creationNumber
        this.Uptop()
      })
    },
    Uptop() {
      let canvas = this.$refs.canvas
      //定义长高
      canvas.width = this.width
      canvas.height = this.height

      setTimeout(() => {
        let number = this.LampData
        let AddNumber = JSON.stringify(JSON.parse(Number(number) + 1))
        AddNumber = this.getNunber(AddNumber)
        let urlImg = `https://hz-mayi.nos-eastchina1.126.net/StarsBg/wrtw-${AddNumber}.png`
        this.urlImg = urlImg
        // this.Imgs = `height:${this.height - 60}px;width:${this.width}px;`

        // urlImg
        for (let i = 1; i <= number; i++) {
          this.UpInit(i)
        }

        this.Starts = setInterval(() => {
          if (this.Start == true) {
            this.Start = false
          } else {
            this.Start = true
          }
        }, 500)
      }, 500)
    },
    UpInit(i) {
      return new Promise((resolve) => {
        let canvas = this.$refs.canvas
        let crx = canvas.getContext('2d')

        let s = this.getNunber(i)
        let url = `https://hz-mayi.nos-eastchina1.126.net/StarsBg/wrtw-${s}.png`
        let img = new Image()
        img.src = url
        img.onload = (() => {
          crx.drawImage(img, 0, 0, this.width, this.height);
          resolve()
        })
      })
    },
    getNunber(str) {
      if (str < 10) {
        return '00' + str
      } else if (str < 100) {
        return '0' + str
      } else if (str == 100) {
        return str
      }else {
        return str
      }
    }
  }

}
</script>

<style scoped lang="scss">
.lamp {
  width: 100%;
  height: 100%;
  background-color: #00022e;
  //background-color: #1989fa;
  @mixin bgSin {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;

  }

  .bg {
    width: 100%;
    background-image: url("../assets/image/lamp/bg.jpg");
    background-size: 100% 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      display: block;
      zoom: 50;
      position: absolute;
      left: 0;
      top: 0;
    }

    .test {
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: red;
      border-radius: 50px;
      left: 30px;
      top: 30px;
    }

    .number {
      position: absolute;
      left: 20px;
      top: 20px;
      color: #c2d6fd;
      font-family: 'Vonwaon';
    }

    .target {
      position: absolute;
      right: 20px;
      top: 20px;
      color: #c2d6fd;
      font-family: 'Vonwaon';

    }

    .light {
      color: #e5757c;
      position: absolute;
      left: 20px;
      top: 80px;
      font-family: 'Vonwaon';
    }

    .one {
      @include bgSin;
    }

  }
}

@font-face {
  font-family: 'Vonwaon';
  src: url('../assets/font/Vonwaon.ttf');
  font-weight: normal;
  font-style: normal;
}


</style>
